import { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'

import useAppContext from 'app/store/useAppContext'
import CheckmarkImg from 'shared/assets/images/thank/checkmark.svg'
import Person1Img from 'shared/assets/images/thank/person1.webp'
import Person2Img from 'shared/assets/images/thank/person2.webp'
import Person3Img from 'shared/assets/images/thank/person3.webp'
import LithuaniaImg from 'shared/assets/images/thank/lithuania.svg.webp'
import GermanyImg from 'shared/assets/images/thank/de-icon.png'
import LatviaImg from 'shared/assets/images/thank/latvia.svg.png'

import './ThankPage.scss'
import '../lib/helpers/thankLogic'

export const ThankPage = () => {
  const {
    store: {
      user: { searchParams, info },
    },
  } = useAppContext()

  useEffect(() => {
    if (searchParams?.metric) {
      ReactPixel.init(searchParams?.metric.toString())
      ReactPixel.track('Lead')
    }
  }, [])

  return (
    <div className="thank_page">
      <header className="header">
        <div className="header__content">
          <h1 className="header__title">Спасибо за регистрацию!</h1>
          <p className="header__description">
            Ваш менеджер свяжется с вами в течение 24 часов, в рабочее время
          </p>
          <img
            className="header__checkmark"
            src={CheckmarkImg}
            alt="checkmark"
          />
        </div>
      </header>
      <main>
        <section className="questions">
          <div className="sticker">
            <h3 className="sticker__title">23 человека</h3>
            <p>присоединились к нам сегодня</p>
          </div>
          <h2 className="questions__title">Часто задаваемые вопросы</h2>
          <ul className="questions__list">
            <li className="questions__item">
              <input type="checkbox" id="rd1" name="rd" />
              <label className="questions__question" htmlFor="rd1">
                <p>Что такое "platform"?</p>
              </label>
              <div className="questions__answer">
                "Platform" - это торговая программа, работающая на алгоритме
                кластеризации и созданная ведущими аналитиками и программистами,
                включая Марка Эллиота Цукерберга. Созданная во время валютного
                кризиса 2015 года, эта программа не только доказала свою
                способность получать прибыль при любом окне валютного скачка, но
                и стала первой системой, которая открывает только прибыльные
                сделки!
              </div>
            </li>
            <li className="questions__item">
              <input type="checkbox" id="rd2" name="rd" />
              <label className="questions__question" htmlFor="rd2">
                Сколько я могу зарабатывать в день?
              </label>
              <div className="questions__answer">
                На данный момент пользователи программы "Platform" зарабатывают
                от 5 000 € до 25 000 € в неделю, то есть от 1 000 € до 5 000 € в
                день.
              </div>
            </li>
            <li className="questions__item">
              <input type="checkbox" id="rd3" name="rd" />
              <label className="questions__question" htmlFor="rd3">
                Сколько зарабатывали предыдущие бета-тестеры?
              </label>
              <div className="questions__answer">
                Когда мы запустили первое бета-тестирование в 2015 году, наши
                бета-тестеры зарабатывали от 500 до 2 000 € в день. Но это была
                первая версия "Platform". За это время мы усовершенствовали
                систему.
              </div>
            </li>
            <li className="questions__item">
              <input type="checkbox" id="rd4" name="rd" />
              <label className="questions__question" htmlFor="rd4">
                Какой опыт и образование нужны для работы с "platform"?
              </label>
              <div className="questions__answer">
                Вам не нужен опыт, специальное образование. Работать с
                программой не сложнее, чем зарегистрировать электронную почту
                или войти в аккаунт Facebook.
              </div>
            </li>
            <li className="questions__item">
              <input type="checkbox" id="rd5" name="rd" />
              <label className="questions__question" htmlFor="rd5">
                <p>
                  Какой процент успешных сделок открывает программа "platform"?
                </p>
              </label>
              <div className="questions__answer">
                С 2015 года, с момента запуска, программа не открыла ни одной
                убыточной сделки.
              </div>
            </li>
            <li className="questions__item">
              <input type="checkbox" id="rd6" name="rd" />
              <label className="questions__question" htmlFor="rd6">
                <p>Из какой страны я могу работать с этой программой?</p>
              </label>
              <div className="questions__answer">
                Абсолютно из любой страны! В этом и прелесть - вы можете
                управлять системой "Platform" из любого уголка земного шара.
                Главное, чтобы у вас был Интернет. Таким образом, даже когда вы
                путешествуете, программа продолжает зарабатывать вам деньги.
              </div>
            </li>
            <li className="questions__item">
              <input type="checkbox" id="rd7" name="rd" />
              <label className="questions__question" htmlFor="rd7">
                <p>Сколько времени займет перевод прибыли на мой счет?</p>
              </label>
              <div className="questions__answer">
                Поскольку PLATFORM Ltd. работает с большими суммами переводов, у
                нас корпоративный уровень сотрудничества с большинством банков.
                Это означает, что в большинстве случаев деньги поступят на ваш
                счет в течение 10-15 минут. В редких случаях (в том числе, если
                вы находитесь за границей), перевод может поступить на следующий
                день.
              </div>
            </li>
            <li className="questions__item">
              <input type="checkbox" id="rd8" name="rd" />
              <label className="questions__question" htmlFor="rd8">
                <p>Как начать работать с программой "platform"?</p>
              </label>
              <div className="questions__answer">
                Для этого вам необходимо зарегистрироваться и активировать
                бесплатную копию программы с помощью формы в верхней части
                страницы. Затем, чтобы запустить программу, вам нужно будет
                только нажать кнопку "Начать автотрейдинг". Однако, сразу после
                регистрации, вы получите подробные инструкции, чтобы у вас не
                возникло никаких проблем или вопросов.
              </div>
            </li>
          </ul>
        </section>
        <section className="reviews">
          <h2 className="reviews__title">ОТЗЫВЫ</h2>
          <ul className="reviews__list">
            <li className="reviews__card">
              <img
                className="reviews__photo"
                src={Person1Img}
                alt="Peter Meier"
              />
              <div>
                <h3 className="reviews__card-title">Юстас Крутулис</h3>
                <div className="reviews__country-info">
                  <img
                    className="reviews__country-icon"
                    src={LithuaniaImg}
                    alt="Canada flag"
                  />
                  <p>Литва</p>
                </div>
                <div className="reviews__profit-info">
                  <p className="reviews__profit-text">Ежемесячная прибыль</p>
                  <p className="reviews__profit-amount">
                    <strong>€150 750</strong>
                  </p>
                </div>
                <p className="reviews__review-text">
                  Как только я увидел программу
                  <span className="bolder-text">
                    <strong></strong>PLATFORM
                  </span>
                  , у меня возникло много вопросов, но менеджер любезно ответил
                  на каждый из них. Я уже второй месяц работаю с системой, и
                  единственный вопрос, который у меня возник, это "Куда
                  потратить эти деньги?".
                </p>
              </div>
            </li>
            <li className="reviews__card">
              <img
                className="reviews__photo"
                src={Person2Img}
                alt="Ursula Hermann"
              />
              <div>
                <h3 className="reviews__card-title">Герта Дрезднер</h3>
                <div className="reviews__country-info">
                  <img
                    className="reviews__country-icon"
                    src={GermanyImg}
                    alt="Germany flag"
                  />
                  <p>Германия</p>
                </div>
                <div className="reviews__profit-info">
                  <p className="reviews__profit-text">Ежемесячная прибыль</p>
                  <p className="reviews__profit-amount">
                    <strong>€110 380</strong>
                  </p>
                </div>
                <p className="reviews__review-text">
                  Отличный сервис для заработка, специалисты прекрасно понимают,
                  как общаться с клиентами и объясняют каждую деталь, с которой
                  можно столкнуться при работе с программой. Мои друзья до сих
                  пор не верят, что с
                  <span className="bolder-text">
                    <strong></strong>PLATFORM
                  </span>{' '}
                  можно зарабатывать, но ничего, скоро нас будет разделять
                  океан.
                </p>
              </div>
            </li>
            <li className="reviews__card">
              <img
                className="reviews__photo"
                src={Person3Img}
                alt="Josef Moser"
              />
              <div>
                <h3 className="reviews__card-title">Арианс Михайловс</h3>
                <div className="reviews__country-info">
                  <img
                    className="reviews__country-icon"
                    src={LatviaImg}
                    alt="Canada flag"
                  />
                  <p>Латвия</p>
                </div>
                <div className="reviews__profit-info">
                  <p className="reviews__profit-text">Ежемесячная прибыль</p>
                  <p className="reviews__profit-amount">
                    <strong>€130 230</strong>
                  </p>
                </div>
                <p className="reviews__review-text">
                  Я наконец-то расплатился со всеми своими долгами с помощью
                  этой платформы. Вывел деньги очень быстро и был в восторге от
                  полученной суммы! Один раз не мог дозвониться до менеджера, но
                  они быстро связались и помогли мне! Рекомендую всем!
                </p>
              </div>
            </li>
          </ul>
        </section>
      </main>
    </div>
  )
}
