import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import LogoImg from 'shared/assets/images/logo.png'
import PrImg from 'shared/assets/images/pr.webp'

import './HomePage.css'
import '../lib/helpers/homeLogic'

export const HomePage = () => {
  const {
    store: { isMobile500, user },
    dispatch,
  } = useAppContext()

  return (
    <>
      <div className="main-desc b-show">
        <div className="main-desc__img"></div>
        <div className="main-desc__body"></div>
      </div>
      <div className="body" style={{ transform: 'translateY(0px)' }}>
        <h1 className="main__title">
          Зарабатывайте до 10 000 евро в месяц на уникальной платформе WhatsApp
        </h1>
        <div className="content">
          <div className="header">
            <div className="header__body">
              <div className="header__name">
                <div className="header__avatar">
                  <img src={LogoImg} alt="LogoImg" />
                </div>
                <div className="header__desc">
                  <p>WhatsApp Bot</p>
                </div>
              </div>
            </div>
          </div>
          <div className="chat">
            <div className="chat__time">
              <p>Сегодня</p>
            </div>
            <div className="chat-content-list"></div>

            <input
              type="text"
              className="inp"
              id="res"
              value="0"
              readOnly={true}
            />

            <div id="form" className="iframe-form">
              <div id="order" className="boxit">
                <div className="frame-container" style={{ padding: '30px' }}>
                  <div className="row-form">
                    <h4 className="form__title">
                      Заполните поле ниже и начните зарабатывать
                    </h4>

                    <AffirmationForm
                      title="Оставьте Ваши контакты, чтобы открыть инвест-счет"
                      user={user}
                      dispatch={dispatch}
                      isMobile500={isMobile500}
                    />
                  </div>

                  <div id="down-box"></div>
                </div>
              </div>
              <div style={{ display: 'block', margin: '0 auto' }}>
                <img
                  src={PrImg}
                  alt="PrImg"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </div>
            </div>

            <div className="hidden_scroll"></div>
          </div>
        </div>
      </div>
    </>
  )
}
